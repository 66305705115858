<template>
  <div class="actions">
    <!-- {{ packServices }} -->
    <ul>
      <li
        v-for="(s,index) in packServices"
        :key="index"
      >
        {{ s.libelle }}
      </li>
    </ul>
  </div>
</template>
    
    <script>
    import {mapGetters} from 'vuex'
    export default {
        props: {object: {type: Object, required: true}},
        data(){
            return  {
            }
        },
        computed: {
            ...mapGetters({
                services: 'packs/services'
            }),
            packServices(){
                let s = JSON.parse(this.object.services)
                return [...this.services].filter(item => s.includes(item.uid))
            }
        }
    }
    </script>
    
    <style>
    
    </style>